import { QueryOptions } from '@apollo/client/core';
import BaseService from '@root/common/base/BaseService';
import { ServiceInterface } from '@root/common/types/service';
import { PageData } from '@root/modules/feed/types/pageContent';
import getPageContentQuery from '@root/modules/feed/graphql/getPageContentQuery.graphql';
import { FeedAdapter } from '@root/modules/feed/utils/FeedAdapter';
import { dataLoader, RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';

type ServiceVariables = {
  id?: number | string;
  domain?: string;
  language: string;
  offset?: number;
};

export default class GetPageContentService extends BaseService implements ServiceInterface {
  private async fetchPageContent(variables: ServiceVariables) {
    const options: QueryOptions<ServiceVariables> = Object.assign({ query: getPageContentQuery }, { variables });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        expireTimeMs: 30000, // 30 seconds
        gracePeriodMs: 24 * 3600 * 1000, // 24 hours
        keyPrefix: 'page_feed',
      },
    };

    const requestWrapper = async (options: QueryOptions<ServiceVariables>): Promise<PageData | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

      const response = await apiProvider.query<PageData>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      const isSectionsEmpty = !response.data.feed?.items[0]?.sections?.items.length;

      // Throw error if frontpage is empty and request is not from lazyload origin
      if (isSectionsEmpty && variables.offset === 0) {
        throw new Error('pageContent.service requestWrapper response error');
      }

      return response.data;
    };

    const redisDataLoader = dataLoader<QueryOptions<ServiceVariables>, PageData | Error>(requestWrapper, dataLoaderOptions);
    const response: PageData | Error = redisDataLoader ? await redisDataLoader.load(options) : await requestWrapper(options);

    if (response instanceof Error) {
      throw response;
    }

    return {
      sections: response?.feed?.items[0]?.sections?.items || [],
      pager: response?.feed?.items[0]?.sections?.pager || [],
    };
  }

  public async fetch(variables: ServiceVariables) {
    try {
      const response = await this.fetchPageContent(variables);

      const feedAdapter = new FeedAdapter(response.sections);
      const adaptedFeed = feedAdapter.adapt();

      return { domain: variables.domain, feed: adaptedFeed, pager: response.pager };
    } catch (error) {
      process.sentry?.captureException(`GetPageContentService fetch failed`, {
        contexts: { data: { variables }, ...(error.message && { message: error.message }), ...(error.cause && { cause: error.cause }) },
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
      this.handleError({ statusCode: 500, message: 'error.unexpected_system_error' });
    }
  }
}

export const pageContentService = new GetPageContentService();

export function fetchPageContentService(variables: ServiceVariables, { req }: { req: PortalRootIncomingMessage }) {
  const pageContentService = new GetPageContentService({ req });
  return pageContentService.fetch(variables);
}
